import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import HeroBanner from "../components/herobanner"
import heroimage from "../assets/images/work_hero@2x.jpg"
import WorkHeroCopy from "../copy/work_hero"

export default function Work({data}) {
  console.log(heroimage);
  return (
      <Layout className="work_overview" pageTitle="Work">
        <HeroBanner title="work hero" heroimage={{publicURL: heroimage}}>
          <div className="work_intro">
            <WorkHeroCopy />
          </div>
        </HeroBanner>

        <div className="work_items">
          {data.allMdx.edges.map(({ node }) => (
              <article key={node.id} className="work_item">
                <Link
                className="overlay"
                to={node.fields.slug}>
                  <h2>
                  {node.frontmatter.title}
                  </h2>
                  <span className="date">{node.frontmatter.date}</span>
                </Link>
                <div className="thumbnail" style={{
                      backgroundImage: `url(${node.frontmatter.thumbnail.publicURL})`
                  }}>
                </div>
              </article>
            ))}
        </div>
      </Layout> 
  )
}

export const query = graphql`
{
    allMdx(filter: {frontmatter: {category: {eq: "work"}}}, sort: {fields: frontmatter___date, order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            category
            date(formatString: "YYYY")
            excerpt
            heroimage {
              publicURL
            }
            thumbnail{
              publicURL
            }
            title
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`